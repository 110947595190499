<template>
    <div class="row">
        <div class="col-md-6">
                <seccion-datos :titulo="datoslineafacturacion" @cambios="guardar()">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <!-- No podrá editarse este campo, solo en la creación -->
                <div v-if="(id > 0)" class="form-group">
                    <label>{{ $t('general.empresa') }}&nbsp;</label>
                        <select disabled class="custom-select form-control-sm" v-model="seleccionado">
                        <option v-for="empresa in empresas" :key="empresa.id" :value="empresa.id_empresa">
                        {{empresa.nombre_empresa}}</option>
                        </select>
                </div>
                 <div v-else class="form-group">
                    <label>{{ $t('general.empresa') }}&nbsp;</label>
                        <select class="custom-select form-control-sm" v-model="seleccionado">
                        <option v-for="empresa in empresas" :key="empresa.id" :value="empresa.id_empresa">
                            {{empresa.nombre_empresa}}</option>
                    </select>
                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="nombre_completo">{{ $t('general.nombre') }}</label>
                                <input type="text" class="form-control nombre_completo dato" v-model="nombre_serie"  placeholder="">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="nombre_completo">{{ $t('general.prefijofactura') }}</label>
                                <input type="text" class="form-control nombre_completo dato" v-model="prefijo_factura_serie" placeholder="">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="nombre_completo">{{ $t('general.prefijoabono') }}</label>
                                <input type="text" class="form-control nombre_completo dato" v-model="prefijo_abono_serie" placeholder="">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="nombre_completo">{{ $t('general.digitosfactura') }}</label>
                                <input type="text" class="form-control nombre_completo dato" v-model="digitos_numero_factura" placeholder="">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="nombre_completo">{{ $t('general.inicioserie') }}</label>
                                <input type="text" class="form-control nombre_completo dato" v-model="tope_serie"  placeholder="">
                            </div>
                        </div>
                    </div>
            </seccion-datos>
        </div>
    </div>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
export default {
    props: ['id','linea'],
    data() {
        return { 
            nombre_serie: '',
            prefijo_factura_serie: '',
            digitos_numero_factura: '',
            prefijo_abono_serie: '',
            tope_serie: '',
            empresas: '',
            seleccionado: '',
            datoslineafacturacion:"Datos Línea Facturación",
        }  
    },
    methods: {
        async listadoEmpresas() {
            /*  modulos/pwgsapi/index.php/empresas/simple */
            this.$store.dispatch('cambiarCargando', true);
            const api = new PwgsApi;
            const datos = await api.get('empresas/simple');
            this.empresas = datos.datos;
            this.$store.dispatch('cambiarCargando', false);
        },
        async obtenerLineas() {
            this.$store.dispatch('cambiarCargando', true);
            this.nombre_serie = this.linea.nombre_serie;
            this.prefijo_factura_serie = this.linea.prefijo_factura_serie;
            this.digitos_numero_factura = this.linea.digitos_numero_factura;
            this.prefijo_abono_serie = this.linea.prefijo_abono_serie;
            this.tope_serie = this.linea.tope_serie;
            this.seleccionado = this.linea.empresas_factura_id_empresa;
            this.$store.dispatch('cambiarCargando', false);
            this.datoslineafacturacion = this.$t('general.datoslineafacturacion');

        },
        async guardar() {
            /* put  modulos/pwgsapi/index.php/lineas-facturacion/:id   */
            const subidadatos = { empresas_factura_id_empresa: this.seleccionado, nombre_serie: this.nombre_serie, prefijo_factura_serie: this.prefijo_factura_serie, prefijo_abono_serie: this.prefijo_abono_serie, digitos_numero_factura: this.digitos_numero_factura, tope_serie: this.tope_serie }
            const api = new PwgsApi;
            if (this.id > 0) {
                try {
                    this.$store.dispatch('cambiarGuardando', true);
                    await api.put('lineas-facturacion/' + this.id, subidadatos);
                    this.$store.dispatch('cambiarGuardando', false);
                    this.$parent.obtenerLineas(this.linea.id);
                    this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
                    this.obtenerLineas();
                }
                catch (error) {
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
                }
            }
            else { 
                /* post  modulos/pwgsapi/index.php/lineas-facturacion/  */
                try {
                    let res;
                    res = await api.post('lineas-facturacion/', subidadatos);
                    this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
                    this.$router.push('/lineasfacturacion/' + res.id);
                }
                  catch (error) {
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
                }
            }
        },
    },
    mounted() {
        this.listadoEmpresas();
        this.obtenerLineas();
    },
    watch: {
        linea() {
            this.obtenerLineas();
        }
    }
}
</script>